import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { Lot, MontantTVA } from '@travauxlib/shared/src/types';
import { shouldDisplayAutoliquidation } from '@travauxlib/shared/src/utils/shouldDisplayAutoliquidation';

type Props = {
  montantsTVA: MontantTVA[];
  lots: Lot[];
};

export const MontantsTVA: React.FC<Props> = ({ montantsTVA, lots }) => (
  <div className="mb-xxs">
    {shouldDisplayAutoliquidation(lots, montantsTVA) ? (
      <div className="flex justify-between">
        <span>TVA 0 %</span>
        <span>Autoliquidation</span>
      </div>
    ) : (
      montantsTVA.map(({ taux, montant, base }) => (
        <SimpleTotal
          key={taux}
          label={
            <>
              TVA {taux} %{' '}
              <span className="text-b2 text-neutral-700">
                {taux !== 0 ? (
                  <>
                    sur la base de <EURCurrency amount={base} />{' '}
                  </>
                ) : (
                  'Autoliquidation'
                )}
              </span>
            </>
          }
          total={montant}
          sectionName={`TVA ${taux} %`}
        />
      ))
    )}
  </div>
);
