import React from 'react';

import { Form } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxListField } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { required } from '@travauxlib/shared/src/utils/form';

import { QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

export const Cuisine = ({ submitAnswer, locations, initialValues }: QuestionProps): JSX.Element => {
  const cuisines = locations.filter(({ typeLocation }) => typeLocation === 'Cuisine');

  const hasACuisine = cuisines.length !== 0;
  const locationsToChooseFrom = hasACuisine ? cuisines : locations;

  return (
    <Form onSubmit={submitAnswer} initialValues={initialValues}>
      {({ handleSubmit, valid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-xl">
            <Title title="Cuisine" />
            {cuisines.length !== 1 && (
              <div className="mb-md">
                <FormLabel label="Dans quelle pièce ?" />
                <ButtonGroupField
                  name="location"
                  validate={required}
                  options={locationsToChooseFrom.map(({ uuid, label }) => ({
                    label,
                    value: uuid,
                  }))}
                />
              </div>
            )}
            <div className="mb-md">
              <FormLabel label="De quelle forme sera la nouvelle cuisine ?" />
              <ButtonGroupField
                validate={required}
                name="shapeOfCuisine"
                options={[
                  { label: 'En L', value: 'l' },
                  { label: 'En U', value: 'u' },
                  { label: 'En I', value: 'i' },
                ]}
              />
            </div>
            <div className="mb-md">
              <label className="font-bold" htmlFor="equipements">
                Faut-il des éléments supplémentaires ?
              </label>
              <CheckboxListField
                name="equipements"
                options={[
                  {
                    label: 'Crédence (carrelage mural)',
                    value: 'credence',
                  },
                ]}
              />
            </div>
            <div className="mb-md">
              <label className="font-bold" htmlFor="electromenager">
                Avez-vous besoin d'électroménager ?
              </label>
              <CheckboxListField
                id="electromenager"
                name="electromenager"
                options={[
                  {
                    label: 'Four',
                    value: 'four',
                  },
                  {
                    label: 'Réfrigérateur',
                    value: 'frigo',
                  },
                  {
                    label: 'Hotte',
                    value: 'hotte',
                  },
                  {
                    label: 'Lave-vaisselle',
                    value: 'lave_vaisselle',
                  },
                  {
                    label: 'Lave-linge',
                    value: 'lave_linge',
                  },
                  {
                    label: 'Plaque de cuisson',
                    value: 'plaque_de_cuisson',
                  },
                  {
                    label: 'Micro-ondes encastrable',
                    value: 'micro_ondes',
                  },
                ]}
              />
            </div>
          </div>
          <div className="!text-sm text-gray-600 mb-md">
            Vous pouvez configurer le carrelage au sol dans la rubrique « Sols ».
          </div>
          <div className="text-center">
            <Button type="submit" disabled={!valid || submitting} loading={submitting}>
              Ajouter
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
