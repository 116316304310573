import React from 'react';

import { ProjectDestinataire } from './ProjectDestinataire';
import { Title } from './Title';

import { AdminContext } from '../../types';

type Props = {
  title?: string;
  adminContext: AdminContext;
};

export const DocumentHeaderWithAdminContext: React.FC<Props> = ({ title, adminContext }) => (
  <div className="mb-md">
    <div className="mb-md text-right">
      <Title isHemea title={title} />
      {adminContext.dealOwnerFullName && (
        <div className="py-xs text-sm bold text-right">
          Projet suivi par {adminContext.dealOwnerFullName}
        </div>
      )}
    </div>
    <ProjectDestinataire
      projectAddress={adminContext.projectAddress}
      customerName={adminContext.customerName}
    />
  </div>
);
