import React from 'react';

import { Form, useFormState } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { InputErrorText } from '@travauxlib/shared/src/components/DesignSystem/components/Input/commons';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { SliderField } from '@travauxlib/shared/src/components/DesignSystem/components/Slider/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { FormData, SuiviLigne } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { parseFormDataToGetLots } from '@travauxlib/shared/src/features/SuiviChantier/utils';
import { required } from '@travauxlib/shared/src/utils/form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChange: (newAvancement: number, newComment: string, hasFournitures: boolean) => void;
  initialPourcentageAvancement: number;
  initialCommentaire?: string;
  initialHasFournitures: boolean;
  ligne: SuiviLigne;
};

type ConflictFormData = {
  pourcentageAvancement: number;
  commentaire: string;
  hasFournitures: boolean;
};

export const LigneAvancementConflictModal: React.FC<Props> = ({
  isOpen,
  onChange,
  initialPourcentageAvancement,
  initialCommentaire,
  initialHasFournitures,
  onClose,
  ligne,
}) => {
  const { values } = useFormState<FormData>();

  const lots = parseFormDataToGetLots(values);
  const currentLot = lots.filter(lot => lot.uuid === ligne.lotUuid)[0];
  const isDevisEnPieceJointe = currentLot.label
    .toLowerCase()
    .includes('Devis en pièce jointe'.toLowerCase());

  const hasFournitureChanged =
    ligne.prixFournituresHT > 0 &&
    ligne.previousCycleHasFournitures !== ligne.previousStepHasFournitures;
  const hasPourcentageAvancementChanged =
    ligne.previousCyclePourcentageAvancement !== ligne.previousStepPourcentageAvancement;

  return (
    <Form<ConflictFormData>
      initialValues={{
        pourcentageAvancement: initialPourcentageAvancement,
        commentaire: initialCommentaire,
        hasFournitures: initialHasFournitures,
      }}
      onSubmit={values => {
        onChange(values.pourcentageAvancement, values.commentaire, values.hasFournitures);
        onClose();

        return undefined;
      }}
    >
      {({ handleSubmit, form }) => {
        const hasFournitureField = form.getFieldState('hasFournitures');
        const hasFournitureError =
          hasFournitureField?.error ||
          (!hasFournitureField?.dirtySinceLastSubmit && hasFournitureField?.submitError);

        return (
          <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title="Modifier l'avancement renseigné"
            validateAction={{
              label: 'Valider la modification',
              type: 'submit',
            }}
            cancelAction={{
              label: 'Annuler',
              onClick: onClose,
            }}
          >
            <ModalContent handleSubmit={handleSubmit}>
              {hasFournitureChanged && (
                <div className="mb-md">
                  <div className="flex justify-between items-center">
                    <div className="text-b2 text-neutral-700">Fourniture réceptionnée</div>
                    <ButtonGroupField
                      name="hasFournitures"
                      options={[
                        { value: false, label: 'Non' },
                        { value: true, label: 'Oui', disabled: !hasPourcentageAvancementChanged },
                      ]}
                      initialValue={hasPourcentageAvancementChanged && ''}
                      validate={required}
                    />
                  </div>
                  {hasFournitureError && hasFournitureField.touched && (
                    <InputErrorText error="Veuillez indiquer le statut de la fourniture" />
                  )}
                </div>
              )}
              {hasPourcentageAvancementChanged && (
                <SliderField
                  className="mb-md"
                  name="pourcentageAvancement"
                  id="pourcentageAvancement"
                  label="Indiquez l'avancement de la prestation"
                  step={isDevisEnPieceJointe ? 1 : 25}
                  maxValue={ligne.previousStepPourcentageAvancement}
                  minValue={ligne.previousCyclePourcentageAvancement}
                  displayedValue={isDevisEnPieceJointe ? 'bound' : 'step'}
                  validate={(pourcentage, values: ConflictFormData) =>
                    pourcentage === ligne.previousStepPourcentageAvancement &&
                    (!hasFournitureChanged ||
                      values.hasFournitures ||
                      values.hasFournitures.toString() === '')
                      ? 'Veuillez ajuster le curseur correspondant à l’avancement réel de la prestation'
                      : undefined
                  }
                />
              )}
              <TextareaField
                name="commentaire"
                id="commentaire"
                label="Motif de désaccord"
                helperText="Votre motif nous aidera à communiquer pourquoi nous lui refusons la validation de cette prestation"
                validate={comment =>
                  !comment ? 'Veuillez fournir un motif de désaccord' : undefined
                }
                rows={2}
              />
            </ModalContent>
          </Modal>
        );
      }}
    </Form>
  );
};
