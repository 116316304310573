import React from 'react';

import classNames from 'classnames';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import {
  DevisCustomization,
  ModalitePaiement,
  ModaliteProvision,
  TypeSuivi,
} from '@travauxlib/shared/src/types';

import { Separator } from 'components/Separator';

type Props = {
  modalitesPaiement: ModalitePaiement[];
  modalitesProvision: ModaliteProvision[];
  isHemeaDeal: boolean;
  typeSuivi: TypeSuivi;
  customization?: DevisCustomization;
};

const Modalites: React.FC<{
  modalites: Array<ModalitePaiement> | Array<ModaliteProvision>;
}> = ({ modalites }) => (
  <>
    {modalites.map(({ montant, pourcentage, label }, index) => (
      <div key={`${label}-${index}`} className="mb-sm flex sm-desktop:mb-0">
        <div className="w-full sm-desktop:max-w-7/12">{label}</div>
        <div className="w-full whitespace-nowrap text-right sm-desktop:max-w-2/12">
          {pourcentage} %
        </div>
        <span className="w-full text-right sm-desktop:max-w-3/12">
          <EURCurrency amount={montant} forceFullPattern />
        </span>
      </div>
    ))}
  </>
);

export const ModalitesDevis: React.FC<Props> = ({
  modalitesPaiement,
  modalitesProvision,
  isHemeaDeal,
  typeSuivi,
  customization,
}) => {
  if (isHemeaDeal) {
    return (
      <div className="mb-xxl break-inside-avoid">
        <ColoredElement
          className={classNames('mb-md text-h4 font-medium', customization?.titleFontStyle)}
          color={customization?.textColor}
        >
          Modalités de paiement
        </ColoredElement>
        <div className="!mb-sm flex flex-wrap px-md">
          <div className="w-full px-md sm-desktop:flex-grow sm-desktop:basis-0">
            <div className="!font-bold">Provisions (versement sur votre compte séquestre)</div>
            <div className="!mb-sm">
              <Modalites modalites={modalitesProvision} />
            </div>
          </div>
          <Separator />
          <div className="w-full px-md sm-desktop:flex-grow sm-desktop:basis-0">
            <div className="!font-bold">Paiements (depuis votre compte séquestre)</div>
            <Modalites modalites={modalitesPaiement} />
          </div>
        </div>
        {typeSuivi === TypeSuivi.TravauxPlanner && (
          <AlertMessage
            level="info"
            title={`Le montant de la prestation d'accompagnement "Travaux Planner" vous sera demandé lors de la première provision de votre compte séquestre, en sus des modalités mentionnées ci-dessus.`}
          />
        )}
      </div>
    );
  }

  return (
    <div className="mb-xxl break-inside-avoid">
      <ColoredElement
        className={classNames('mb-md text-h4 font-medium', customization?.titleFontStyle)}
        color={customization?.textColor}
      >
        Modalités de paiement
      </ColoredElement>
      <div className="flex flex-wrap">
        <div className="!mb-lg w-full sm-desktop:!mb-0 sm-desktop:max-w-5/12">
          <Modalites modalites={modalitesPaiement} />
        </div>
      </div>
    </div>
  );
};
