import React from 'react';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { CircularProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

type Props = {
  pourcentageAvancement: number;
  projectShortAddress: string;
  dateOuverture: string;
};

export const RecapChantierCard: React.FC<Props> = ({
  pourcentageAvancement,
  dateOuverture,
  projectShortAddress,
}) => (
  <Card
    state="elevated"
    className="!rounded-md"
    bodyClassNames="!p-sm flex items-center"
    to="etapes"
  >
    <CircularProgress value={pourcentageAvancement} />
    <div className="flex flex-col grow gap-xxs text-b2 text-neutral-600 ml-xs mr-xxs truncate">
      <div className="flex items-center gap-xxs text-b1 text-neutral-800">
        <span className="font-bold">Mon chantier</span>{' '}
        {pourcentageAvancement === 100 ? (
          <Tag label="Terminé" variant="primary" size="sm" />
        ) : (
          <Tag label="En cours" variant="success" size="sm" />
        )}
      </div>
      <div className="truncate">{projectShortAddress}</div>
      {dateOuverture && (
        <div className="truncate">Date d'ouverture {formatFrenchDate(dateOuverture)}</div>
      )}
    </div>
    <IconButton className="shrink-0">
      <ChevronRight />
    </IconButton>
  </Card>
);
