import React from 'react';

import { DocumentDisplayLayout } from 'components/DocumentDisplayLayout';
import { DownloadPDFButton } from 'components/DownloadPDFButton';
import { SuiviChantierClient } from 'types';

import { SuiviChantierContent } from './SuiviChantierContent';
import { SuiviChantierToolbar } from './SuiviChantierToolbar';

type Props = {
  suiviChantier: SuiviChantierClient;
};

const getPrintSuiviChantierUrl = (uuid: string, token: string): string =>
  `${APP_CONFIG.apiURL}/client/suivi-chantier/${token}/pdf`;

export const SuiviChantierWrapper: React.FC<Props> = ({ suiviChantier }) => {
  const { title } = suiviChantier;

  return (
    <DocumentDisplayLayout
      header={
        <div className="flex justify-between relative border-b-1 h-[4.5rem]">
          <div className="flex items-center">
            <div className="px-md">
              <div className="font-bold">Titre</div>
              <div>{title}</div>
            </div>
          </div>
          <div className="flex items-center p-xs">
            <DownloadPDFButton
              url={getPrintSuiviChantierUrl(suiviChantier.dealUuid, suiviChantier.token)}
            />
          </div>
        </div>
      }
      sidebar={<SuiviChantierToolbar lots={suiviChantier.lots} />}
    >
      <div className="bg-neutral-100 px-sm md-desktop:px-[3.5rem] py-[3.5rem] overflow-auto h-full">
        <div className="bg-neutral-0 shadow-lg">
          <SuiviChantierContent suiviChantier={suiviChantier} />
        </div>
      </div>
    </DocumentDisplayLayout>
  );
};
