import classNames from 'classnames';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import {
  DevisCustomization,
  MediateurConsommation as ProMedateurConsommation,
} from '@travauxlib/shared/src/types';
import { truncate } from '@travauxlib/shared/src/utils/format';

type Props = {
  proMediateurConsommation: ProMedateurConsommation;
  customization?: DevisCustomization;
};

export const MediateurConsommation: React.FC<Props> = ({
  proMediateurConsommation,
  customization,
}) => {
  const {
    fullName,
    googleMapsAddress: { formatted_address: address },
    website,
    phoneNumber,
  } = proMediateurConsommation;

  return (
    <div>
      <ColoredElement
        className={classNames('text-h4 font-medium', customization?.titleFontStyle)}
        color={customization?.textColor}
      >
        Médiateur de la consommation
      </ColoredElement>
      <div className="mb-md max-w-full">
        En cas de différend qui pourrait apparaître pour l’exécution du contrat, le client et
        l’entreprise privilégieront la recherche d’une solution amiable après une réclamation écrite
        du client auprès de l’entreprise. Si aucune solution amiable n’est trouvée, le client peut
        soumettre le différend au médiateur de la consommation:{' '}
        <span>
          {fullName}, {address},{' '}
        </span>
        <Link variant="secondary" size="sm" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </Link>
        {website && (
          <Link
            variant="secondary"
            size="sm"
            className="max-w-full overflow-x-hidden"
            href={website}
            target="_blank"
          >
            {', '}
            {truncate(website, 60)}
          </Link>
        )}
      </div>
    </div>
  );
};
