import React from 'react';

import classNames from 'classnames';
import _range from 'lodash/range';

import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFull.svg?react';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';

import { negativeLeftMargins, stickyTop } from '../utils/classNames';
import { contentHeight } from '../utils/constants';

type Props = {
  imageUrl: string;
  avis: string;
  auteur: string;
};
export const Avis: React.FC<Props> = ({ imageUrl, avis, auteur }) => (
  <div
    className={classNames('col-span-3', 'hidden sm-desktop:block', stickyTop, negativeLeftMargins)}
    style={{ height: contentHeight }}
  >
    <ImgLazy className="!h-full w-full" url={imageUrl} width={600} />
    <div
      className="absolute p-xl pr-md bottom-0 max-w-full bg-gradient-to-b from-transparent to-transparent/75"
      style={{ overflowWrap: 'break-word' }}
    >
      <div className="text-h3 text-neutral-0 font-bold mb-xs">"{avis}"</div>
      <div className="text-h5 text-neutral-0">{auteur}, Avis client</div>
      <div>
        {_range(5).map(value => (
          <StarFull className="text-neutral-0 w-lg h-lg" key={value} />
        ))}
      </div>
    </div>
  </div>
);
