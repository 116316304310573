import React from 'react';

import ArchitectureOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ArchitectureOutline.svg?react';
import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import Project from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';

import { EtapeConfig, EtapeProjet } from '../types';

export const etapeConfigs: { [K in EtapeProjet]: EtapeConfig } = {
  [EtapeProjet.Etude]: {
    title: 'Étude du projet',
    icon: <Project />,
    items: [
      'Prendre contact avec un chef de projet',
      'Importer les documents demandés',
      'Lire et valider le cahier des charges préparé par le chef de projet',
    ],
  },
  [EtapeProjet.Archi]: {
    title: (
      <>
        Conception architecte{' '}
        <span className="text-b2 text-neutral-600 font-normal">(facultatif)</span>
      </>
    ),
    icon: <ArchitectureOutline />,
    items: [
      'Prendre contact avec un architecte d’intérieur',
      'Choisir un créneau pour la prise de mesures',
      "Signer le devis d'honoraires",
      'Choisir une des deux propositions 2D réalisées par l’architecte',
      'Sélectionner vos fournitures et équipements',
      'Lire le compte rendu et valider les plans 3D',
    ],
  },
  [EtapeProjet.Pro]: {
    title: 'Consultation entreprises',
    icon: <Quote />,
    items: [
      'Prendre rendez-vous avec deux entrepreneurs',
      'Relire et challenger les devis avec votre chef de projet',
    ],
  },
  [EtapeProjet.Devis]: {
    title: 'Signature du devis',
    icon: <Quote />,
    items: [
      'Choisir le type d’accompagnement que vous souhaitez',
      'Sélectionner un devis et le signer',
    ],
  },
  [EtapeProjet.AvantChantier]: {
    title: 'Préparation du chantier',
    icon: <HandyManOutline />,
    items: [
      'Échanger avec un chargé d’accompagnement travaux ou un travaux planner',
      'Importer des photos de votre bien avant le début du chantier',
      'Finaliser la sélection de vos fournitures et équipements',
      'Vérifier l’exactitude des informations liées au chantier',
    ],
  },
  [EtapeProjet.Chantier]: {
    title: 'Chantier en cours',
    icon: <HandyManOutline />,
    items: [
      'Importer des photos de votre bien pendant le chantier',
      'Débloquer les fonds nécessaires pour l’avancée du chantier',
      'Assister à vos rendez-vous de suivi avec votre chargé d’accompagnement travaux ou travaux planner',
    ],
  },
  [EtapeProjet.ApresChantier]: {
    title: 'Chantier terminé',
    icon: <HandyManOutline />,
    items: [
      'Participer à la réunion de préparation de réception',
      'Vérifier la conformité des travaux lors du rendez-vous de réception',
      'Envoyer le procès-verbal signé et transmis par votre entrepreneur',
      'Assister au point de fin de chantier avec votre chargé d’accompagnement travaux ou votre travaux planner',
    ],
  },
};
