import React from 'react';

import { useNavigate } from 'react-router-dom';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';

import { GuidesSectionConfig } from '../types';

type Props = {
  section: GuidesSectionConfig;
};

export const Section: React.FC<Props> = ({ section }) => {
  const navigate = useNavigate();
  const configsArray = Object.entries(section.configs).map(([key, value]) => ({
    tag: key,
    ...value.cardConfig,
  }));

  return (
    <div>
      <div className="my-sm mx-md sm-desktop:mx-0 sm-desktop:text-h4 text-h5 font-bold text-neutral-800">
        {section.title}
      </div>
      <div className="py-xxs bg-neutral-0 flex flex-wrap shadow-xss rounded-xxs">
        {configsArray.map(config => (
          <ListItem
            key={config.tag}
            state="filled"
            className="last:border-none"
            size="sm"
            middle={{
              title: config.title,
            }}
            right={{
              icon: <ChevronRight />,
              type: 'iconButton',
              onClick: () => navigate(`./${config.tag}`),
            }}
          />
        ))}
      </div>
    </div>
  );
};
