import React from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ChantierTransaction } from '@travauxlib/shared/src/features/Chantiers/types';
import { HistorySuiviHeader } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/HistoryHeader';
import { SuiviChantierHistoryTable } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/SuiviChantierHistoryTable';

import { useSuiviChantierHistoryList } from 'features/EspaceClient3/api/useSuiviChantierHistoryList';

import { TransactionsHistoryTable } from './TransactionsHistoryTable';

type Props = {
  transactions: ChantierTransaction[];
  buildNavigationUrl: (suiviChantierUuid: string) => string;
};

export const History: React.FC<Props> = ({ transactions, buildNavigationUrl }) => {
  const [searchParams] = useSearchParams();
  const displayMode = searchParams.get('mode');
  const navigate = useNavigate();
  const { chantierUuid } = useParams<{ chantierUuid: string }>();
  const { suiviChantierHistoryList } = useSuiviChantierHistoryList(chantierUuid!);

  React.useEffect(() => {
    if (!displayMode) {
      navigate(`?mode=global`, { replace: true });
    }
  }, [displayMode]);

  if (!displayMode) {
    return <Loader size="xs" />;
  }

  return (
    <div>
      <HistorySuiviHeader
        setDisplayMode={(displayMode: string) => {
          navigate(`?mode=${displayMode}`);
        }}
        displayMode={displayMode}
        hasAtLeastOneSuiviChantierHistory={(suiviChantierHistoryList?.length ?? 0) > 0}
      />
      <div className="bg-neutral-0 py-xxs rounded-xxs shadow-xxs">
        {displayMode === 'global' ? (
          <TransactionsHistoryTable transactions={transactions} />
        ) : (
          <SuiviChantierHistoryTable
            suiviChantierHistoryList={suiviChantierHistoryList || []}
            getRowClickUrl={buildNavigationUrl}
          />
        )}
      </div>
    </div>
  );
};
