import React from 'react';

import classNames from 'classnames';
import { useField } from 'react-final-form';

import Box from '@travauxlib/shared/src/components/DesignSystem/assets/Box.svg?react';
import Comment from '@travauxlib/shared/src/components/DesignSystem/assets/Comment.svg?react';
import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import {
  ProgressBar,
  ProgressBarVariant,
} from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { textStyleByConflictStatus } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/utils/contants';
import { LigneStatus } from '@travauxlib/shared/src/types';

import { ConflictStatus, SuiviLigne } from '../../types';

type Props = {
  ligne: SuiviLigne;
  namePrefix: string;
  includeUnchangedLines?: boolean;
};

export const AvancementInfo: React.FC<Props> = ({ ligne, namePrefix, includeUnchangedLines }) => {
  const {
    input: { value: suiviStatus },
  } = useField<ConflictStatus>(`${namePrefix}.suiviStatus`);

  const displayFournitureInfos =
    ligne.prixFournituresHT > 0 &&
    (includeUnchangedLines ||
      ligne.previousCycleHasFournitures !== ligne.previousStepHasFournitures);
  const displayPourcentageAvancementInfos =
    includeUnchangedLines ||
    ligne.previousCyclePourcentageAvancement !== ligne.previousStepPourcentageAvancement;

  return (
    <>
      {(displayFournitureInfos || displayPourcentageAvancementInfos) && (
        <div className="flex flex-col gap-xxs">
          {displayFournitureInfos && (
            <div className="flex justify-between text-b2">
              <div className="text-neutral-700">
                <Box width={20} height={20} className="mr-xxs" />
                Fourniture réceptionnée
              </div>
              <div
                className={classNames(
                  'font-bold',
                  suiviStatus
                    ? ligne.hasFournitures
                      ? textStyleByConflictStatus['agreed']
                      : textStyleByConflictStatus['rejected']
                    : 'text-primary',
                )}
              >
                {ligne.hasFournitures ? 'Oui' : 'Non'}
              </div>
            </div>
          )}
          {displayPourcentageAvancementInfos && (
            <div className="flex justify-between text-b2">
              <div className="text-neutral-700">
                <HandyManOutline width={20} height={20} className="mr-xxs" />
              </div>
              <ProgressBar
                className="mb-xxs w-full"
                size="md"
                label={
                  <span className="font-normal text-neutral-700">Avancement de la prestation</span>
                }
                value={[
                  ...[
                    {
                      value: ligne.pourcentageAvancement,
                      variant: (suiviStatus ? 'success' : 'primary') as ProgressBarVariant,
                    },
                  ],
                  ...(suiviStatus === 'rejected'
                    ? [
                        {
                          value:
                            ligne.previousStepPourcentageAvancement - ligne.pourcentageAvancement,
                          variant: 'error' as ProgressBarVariant,
                        },
                      ]
                    : []),
                ]}
                formattedValue={
                  <>
                    {ligne.pourcentageAvancement !== ligne.previousStepPourcentageAvancement && (
                      <span className="line-through text-xs text-neutral-800 mr-xxs font-normal">
                        {ligne.previousStepPourcentageAvancement}%
                      </span>
                    )}
                    <span
                      className={classNames(
                        { 'text-b2': suiviStatus },
                        suiviStatus
                          ? ligne.pourcentageAvancement === ligne.previousStepPourcentageAvancement
                            ? textStyleByConflictStatus['agreed']
                            : textStyleByConflictStatus['rejected']
                          : 'text-primary',
                      )}
                    >
                      {ligne.pourcentageAvancement}%
                    </span>
                  </>
                }
              />
            </div>
          )}
          {suiviStatus === 'rejected' && ligne.commentaire && (
            <div className="flex text-b2 text-neutral-700">
              <Comment width={20} height={20} className="mr-xxs shrink-0" />
              <div>
                Motif du désacord
                <div className="text-neutral-800 italic">"{ligne.commentaire}"</div>
              </div>
            </div>
          )}
          {ligne.status === LigneStatus.Free && (
            <InlineAlert level="light">
              Pour les prestations offertes, l’avancement n’impacte pas le montant à libérer
            </InlineAlert>
          )}
        </div>
      )}
    </>
  );
};
