import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useContacts } from 'features/EspaceClient3/api/useContacts';
import { MyAdviserInfo } from 'features/EspaceClient3/components/MyAdviserInfo';

import { Person } from '../types';

type Props = {
  projectUuid: string;
};

export const MyAdvisorSection: React.FC<Props> = ({ projectUuid }) => {
  const { isLoading, contacts } = useContacts();
  const contact: Person | undefined = contacts[projectUuid];

  if (isLoading) {
    return (
      <div className="my-sm mx-auto">
        <Loader />
      </div>
    );
  }

  return contact ? (
    <div>
      <div className="text-neutral-800 font-bold text-h5 my-sm mx-md sm-desktop:mx-0">
        Une question ? Besoin d'aide ?
      </div>
      <Card className="!rounded-none sm-desktop:!rounded-xxs">
        <MyAdviserInfo contact={contact} />
      </Card>
    </div>
  ) : null;
};
