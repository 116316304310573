import React from 'react';

import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ClientActionType } from '@travauxlib/shared/src/types';

import { useSkipAction } from '../api/useSkipAction';

type OwnProps = {
  projectUuid: string;
  actionType: ClientActionType;
};

type Props = OwnProps & {
  handleClose: () => void;
};

export const SkipDocumentModal: React.FC<Props> = ({ projectUuid, actionType, handleClose }) => {
  const { skipAction } = useSkipAction();

  return (
    <ModalContent
      title="Passer cette étape ?"
      validateAction={{
        label: 'Confirmer',
        onClick: () => {
          skipAction({ projectUuid, actionType });
          handleClose();
        },
      }}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
    >
      <div className="text-b2 text-neutral-600">
        Vous nous confirmez que vous ne pouvez pas ajouter ce document pour votre projet de
        rénovation.
      </div>
    </ModalContent>
  );
};

export const useOpenSkipDocumentModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(SkipDocumentModal, {
      closable: true,
      ...props,
    });
};
