import React, { ReactNode } from 'react';

import classNames from 'classnames';

import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

type Props = { toggleRecallModal?: () => void; logo: JSX.Element };

export const HeaderContainer: React.FC<{ children: ReactNode; className: string }> = ({
  children,
  className,
}) => <div className={classNames('h-[5.3625rem]', className)}>{children}</div>;

export const Header: React.FC<Props> = ({ toggleRecallModal, logo }) => (
  <HeaderContainer className="border-b sticky top-0 left-0 z-20 right-0 flex justify-between items-center px-xs sm-desktop:px-lg bg-neutral-0">
    <div className="!py-sm !pr-sm !flex !items-center">
      <div className="!mr-sm">{logo}</div>
      <span className="!hidden sm-desktop:!block text-b2 !mt-xs !mb-0">en partenariat avec</span>
      <div className="!ml-sm">
        <ImgLazy alt="hemea" url={`${baseImgixUrl}/static/logo-hemea-black-v2.png`} height={15} />
      </div>
    </div>
    {toggleRecallModal && (
      <Button onClick={() => toggleRecallModal()} leftIcon={<Phone />} size="sm">
        Je souhaite être rappelé
      </Button>
    )}
  </HeaderContainer>
);
