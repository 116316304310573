import React from 'react';

import { Field, Form } from 'react-final-form';

import { ButtonGroup } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxListField } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { Gamme } from '@travauxlib/shared/src/types';

import { QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

type FormValues = {
  location?: string;
  equipements?: string[];
  answerType: string;
};

const equipementsWc = [
  { label: 'Lave-mains', value: 'lave_mains' },
  {
    label: 'Meuble simple vasque',
    value: 'lavabo_simple',
  },
  { label: 'WC', value: 'wc' },
];
const equipementsSdb = (gamme: Gamme): Array<{ label: string; value: string }> => [
  {
    label: ['premium', 'exclusif'].includes(gamme) ? "Douche à l'italienne" : 'Douche',
    value: 'douche',
  },
  { label: 'Baignoire', value: 'baignoire' },
  {
    label: 'Meuble simple vasque',
    value: 'lavabo_simple',
  },
  {
    label: 'Meuble double vasque',
    value: 'lavabo_double',
  },
  { label: 'Lave-linge', value: 'lave_linge' },
  { label: 'WC', value: 'wc' },
  {
    label: 'Sèche-serviette',
    value: 'seche_serviette',
  },
];

export const Sdb = ({
  locations,
  submitAnswer,
  initialValues,
  gamme,
}: QuestionProps): JSX.Element => (
  <Form<FormValues>
    initialValues={initialValues}
    onSubmit={submitAnswer}
    mutators={{
      handleChangeLocation: ([locationUuid], state, { changeValue }) => {
        changeValue(state, 'location', () => locationUuid);
        changeValue(state, 'equipements', () => []);
      },
    }}
  >
    {({ form: { mutators }, handleSubmit, valid, values, submitting }) => {
      const eligiblesLocation = locations.filter(({ typeLocation }) =>
        ["Salle d'eau", 'WC', 'Salle de bain'].includes(typeLocation),
      );

      const chosenLocation =
        values.location && locations.find(({ uuid }) => uuid === values.location);

      const isWCSelected = chosenLocation && chosenLocation.typeLocation === 'WC';

      const locationsToChooseFrom = eligiblesLocation.length > 0 ? eligiblesLocation : locations;

      return (
        <form onSubmit={handleSubmit}>
          <div className="mb-xl">
            <Title title="Salle de bain / WC" />
            {locationsToChooseFrom.length !== 1 && (
              <div className="mb-md">
                <Field
                  name="location"
                  render={({ input }) => (
                    <>
                      <FormLabel label="Dans quelle pièce ?" />
                      <ButtonGroup
                        options={locationsToChooseFrom.map(({ uuid, label }) => ({
                          value: uuid,
                          label,
                          className: 'whitespace-nowrap',
                        }))}
                        value={input.value}
                        onChange={mutators.handleChangeLocation}
                      />
                    </>
                  )}
                />
              </div>
            )}
            <div className="mb-md">
              <label className="font-bold" htmlFor="equipements">
                Quels équipements voulez-vous installer ?
              </label>
              <CheckboxListField
                name="equipements"
                options={isWCSelected ? equipementsWc : equipementsSdb(gamme)}
              />
            </div>
          </div>
          <div className="!text-sm text-gray-600 mb-md">
            Vous pouvez configurer le carrelage au sol dans la rubrique « Sols ».
          </div>
          <div className="text-center">
            <Button type="submit" disabled={!valid || submitting} loading={submitting}>
              Ajouter
            </Button>
          </div>
        </form>
      );
    }}
  </Form>
);
