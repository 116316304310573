import React from 'react';

import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

const numbers = [
  {
    title: '+ de 3000',
    imageName: 'home.png',
    description: <div>Chantiers réalisés par hemea</div>,
  },
  {
    title: '8M €',
    imageName: 'graph.png',
    description: <div>Fonds levés avec Xavier Niel (Fondateur de Free)</div>,
  },
  {
    title: '+ de 95%',
    imageName: 'people.png',
    description: (
      <div>
        de clients satisfaits <br />
        <a
          className="text-dark"
          target="_blank"
          rel="noreferrer"
          href="https://fr.trustpilot.com/review/hemea.com"
        >
          <u>Voir les avis sur Trustpilot</u>
        </a>
      </div>
    ),
  },
];

export const Hemea: React.FC = () => (
  <div>
    <div className="mb-md text-h4 font-medium">hemea en quelques chiffres</div>
    <div className="flex justify-around mt-md mb-xl">
      {numbers.map(({ title, description, imageName }) => (
        <div key={title} className="mb-sm flex justify-center">
          <ImgLazy
            url={`${baseImgixUrl}/static/devis/${imageName}`}
            className="mr-sm max-h-[52px]"
            alt=""
            width={32}
            height={52}
          />
          <div>
            <h4 className="font-bold">{title}</h4>
            <small>{description}</small>
          </div>
        </div>
      ))}
    </div>
  </div>
);
