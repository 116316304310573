import React from 'react';

import dayjs from 'dayjs';

import Check from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { AnimatedCircleProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress/AnimatedCircleProgress';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { Chantier } from 'features/EspaceClient3/types';

// TODO : faire un test pour les montants de provisions
export const ProvisionCard: React.FC<{ chantier: Chantier }> = ({
  chantier: { totalProvisions, appelsDeProvision, montantTotalTTC },
}) => {
  const totalProvisionsRestantes =
    montantTotalTTC - totalProvisions >= 0 ? montantTotalTTC - totalProvisions : 0;
  const pourcentageAvancementProvisions =
    (totalProvisions / montantTotalTTC) * 100 > 100
      ? 100
      : (totalProvisions / montantTotalTTC) * 100;
  const fullyProvisionned = pourcentageAvancementProvisions >= 100;
  const nextAppelProvisionDate = appelsDeProvision
    .filter(adp => adp.status === 'pending')
    .sort((adpA, adpB) => dayjs(adpB.dateEffet).diff(dayjs(adpA.dateEffet)))[0]?.dateEffet;
  const nextAppelProvision = nextAppelProvisionDate
    ? dayjs(nextAppelProvisionDate).format('DD/MM/YYYY')
    : 'Aucun';

  return (
    <Card aria-label="provision" className="!rounded-md" role="section">
      <div className="flex justify-between gap-xs">
        <div>
          <AnimatedCircleProgress
            value={Math.floor(pourcentageAvancementProvisions)}
            size="sm"
            variant="info"
            icon={
              fullyProvisionned ? (
                <span className="animate-pulse duration-500 ease-in finite">
                  <Check />
                </span>
              ) : undefined
            }
          />
        </div>
        <div className="flex flex-col grow">
          <div className="flex justify-between mb-xs text-neutral-800 font-bold">
            <div className="text-b1">Provisions</div>
          </div>
          {fullyProvisionned ? (
            <div className="flex justify-between text-b2">
              <div className="text-neutral-600">Total provisionné</div>
              <EURCurrency className="font-medium text-neutral-800" amount={totalProvisions} />
            </div>
          ) : (
            <>
              <div className="flex justify-between text-b2">
                <div className="text-neutral-600">Déjà provisionné</div>
                <EURCurrency className="font-medium text-neutral-800" amount={totalProvisions} />
              </div>
              <div className="flex justify-between text-b2">
                <div className="text-neutral-600">En attente</div>
                <EURCurrency
                  className="font-medium text-neutral-800"
                  amount={totalProvisionsRestantes}
                />
              </div>
              <div className="flex justify-between text-b2">
                <div className="text-neutral-600">Prochaine échéance</div>
                <div className="text-neutral-800">{nextAppelProvision}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
