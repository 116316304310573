import React from 'react';

import classNames from 'classnames';

type Props = {
  icon: JSX.Element;
  className?: string;
  children: React.ReactNode;
};

export const ReassuranceItem: React.FC<Props> = ({ icon, className, children }) => (
  <div
    className={classNames(
      'col-span-1 mb-md last:mb-0 sm-desktop:mb-0 flex sm-desktop:flex-col items-start sm-desktop:items-center sm-desktop:justify-center',
      className,
    )}
  >
    <div className="flex justify-center mr-xxs sm-desktop:mr-0 sm-desktop:mb-xxs">{icon}</div>
    <div className="flex sm-desktop:flex-col items-center font-bold text-b2 text-center">
      {children}
    </div>
  </div>
);
