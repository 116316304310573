import React, { useEffect } from 'react';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeft.svg?react';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { GuidePageConfig } from '../../types';
import { ArticleCard } from '../Articles/ArticleCard';

export const GuidePage: React.FC<GuidePageConfig> = ({
  title,
  introduction,
  articles,
  tracking,
}) => {
  useEffect(() => tracking(), [tracking]);

  return (
    <div className="mx-md">
      <div className="flex items-center my-sm gap-xs">
        <IconButtonLink to=".." title={title.toString()}>
          <ArrowLeft className="shrink-0" />
        </IconButtonLink>
        <div className="text-h5 text-neutral-800 font-bold">{title}</div>
      </div>
      {introduction && <div className="mb-md text-neutral-600">{introduction}</div>}
      <div className="grid grid-cols-2 gap-md tablet:grid-cols-8">
        {articles.map((article, index) => (
          <ArticleCard key={index} {...article} />
        ))}
      </div>
    </div>
  );
};
