import React from 'react';

import Picto404 from '@travauxlib/shared/src/components/DesignSystem/assets/Picto404.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

export const OupsPage: React.FC = () => (
  <div className="flex gap-xl sm-desktop:mt-3xl mx-md sm-desktop:mx-0">
    <div className="hidden sm-desktop:block">
      <Picto404 className="w-[15rem]" />
    </div>
    <div className="flex flex-col w-fit">
      <div className="text-neutral-800 font-bold text-h2 sm-desktop:mb-lg text-center sm-desktop:text-start">
        Oups !
      </div>
      <div className="sm-desktop:hidden py-xs flex justify-center">
        <Picto404 className="w-[7.5rem]" />
      </div>
      <div className="text-neutral-800 font-medium text-h4 mb-xs">
        Désolé, une erreur s'est produite.
      </div>
      <div className="text-neutral-700 text-b1 mb-xl">
        Si l'erreur persiste, n'hésitez pas à nous contacter au{' '}
        <span className="text-neutral-800 font-bold">01 82 28 56 00</span>.
      </div>
      <div className="flex">
        <ButtonLink className="flex grow sm-desktop:grow-0 justify-center" to="/">
          Recharger la page
        </ButtonLink>
      </div>
    </div>
  </div>
);
