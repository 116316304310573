import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';

import { QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

export const Menuiseries = ({ submitAnswer, initialValues }: QuestionProps): JSX.Element => {
  const suffix = 'm';
  return (
    <Form initialValues={initialValues} onSubmit={submitAnswer}>
      {({ handleSubmit, valid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-xl">
            <Title title="Menuiseries" />

            <div>
              <div className="text-h5 mb-sm">Combien de placards faut-il...</div>
              <div className="mb-md flex -mx-md flex-wrap">
                <div className="w-full max-w-1/2 px-md">
                  <NumberInputField
                    label="Démolir"
                    id="deposePlacards"
                    name="deposePlacards"
                    suffix={suffix}
                  />
                </div>
                <div className="w-full max-w-1/2 px-md">
                  <NumberInputField
                    label="Monter"
                    id="posePlacards"
                    name="posePlacards"
                    suffix={suffix}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="text-h5 mb-sm">Combien de bibliothèques faut-il...</div>
              <div className="mb-md flex -mx-md flex-wrap">
                <div className="w-full max-w-1/2 px-md">
                  <NumberInputField
                    label="Démolir"
                    id="deposeBibliotheque"
                    name="deposeBibliotheque"
                    suffix={suffix}
                  />
                </div>
                <div className="w-full max-w-1/2 px-md">
                  <NumberInputField
                    label="Monter"
                    id="poseBibliotheque"
                    name="poseBibliotheque"
                    suffix={suffix}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="text-h5 mb-sm">Combien de dressings faut-il...</div>
              <div className="mb-md flex -mx-md flex-wrap">
                <div className="w-full max-w-1/2 px-md">
                  <NumberInputField
                    label="Démolir"
                    id="deposeDressing"
                    name="deposeDressing"
                    suffix={suffix}
                  />
                </div>
                <div className="w-full max-w-1/2 px-md">
                  <NumberInputField
                    label="Monter"
                    id="poseDressing"
                    name="poseDressing"
                    suffix={suffix}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button type="submit" disabled={!valid || submitting} loading={submitting}>
              Valider
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
