import React from 'react';

import CalculateOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CalculateOutline.svg?react';
import ChatOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ChatOutline.svg?react';
import Search from '@travauxlib/shared/src/components/DesignSystem/assets/Search.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';

import { StructureCard } from 'components/Card';

type Props = {
  title: string;
  subTitle: string;
  icon: React.ReactNode;
};

const ReassuranceCell: React.FC<Props> = ({ title, subTitle, icon }) => (
  <div className="tablet:col-span-1 col-span-full flex tablet:flex-col flex-row tablet:items-center tablet:justify-start">
    <ColoredCircle variant="secondary" size="lg">
      {icon}
    </ColoredCircle>
    <div className="ml-md tablet:ml-0 tablet:text-center">
      <div className="text-b2 font-bold">{title}</div>
      <div className="text-b2 text-neutral-700 tablet:text-center">{subTitle}</div>
    </div>
  </div>
);

export const ReassuranceProjectSave: React.FC = () => (
  <StructureCard className="-mx-md sm-desktop:mx-0 mb-md">
    <div className="text-b1 font-bold">Les avantages de parler avec un expert</div>
    <div className="grid grid-cols-3 tablet:gap-x-md gap-y-md mt-md">
      <ReassuranceCell
        title="Estimer"
        subTitle="Le montant de vos travaux de rénovation"
        icon={<CalculateOutline />}
      />
      <ReassuranceCell
        title="Conseiller"
        subTitle="Sur votre projet et le choix de l'entreprise"
        icon={<ChatOutline />}
      />
      <ReassuranceCell
        title="Orienter"
        subTitle="Autour des différentes offres hemea"
        icon={<Search />}
      />
    </div>
  </StructureCard>
);
