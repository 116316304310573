import React from 'react';

import HemeaVoice from '@travauxlib/shared/src/components/DesignSystem/assets/HemeaVoice.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { ActionCards } from './components/ActionCards';

import { usePartner } from '../../api/usePartner';
import { InvalidTokenAlertMessage } from '../PartnerSendLeadForm/components/InvalidTokenAlertMessage';

export const HomePartner: React.FC = () => {
  const { partner, isLoading } = usePartner();

  const hasManager =
    partner?.managerFirstName &&
    partner?.managerLastName &&
    (partner?.managerEmail || partner?.managerPhone);

  if (isLoading) {
    return <LoaderWrapper />;
  }

  if (!partner) {
    return (
      <InvalidTokenAlertMessage title="Le token détecté dans l’URL n’est pas valide, merci de vérifier qu’il n’est pas tronqué" />
    );
  }

  const isPartnerManagerInternalToHemea = partner.managerEmail?.endsWith('hemea.com');
  const roleDescriptionPhrase = isPartnerManagerInternalToHemea
    ? 'votre Responsable Partenariat'
    : 'votre contact';

  return (
    <LayoutGrid className="py-xl">
      <div className="col-span-full md-desktop:col-span-10 md-desktop:col-start-2">
        <div className="mb-xl text-center text-h3 font-bold">
          Bienvenue dans votre espace partenaire hemea !
        </div>
        {hasManager && (
          <Card className="mb-xl">
            <div className="flex items-center">
              <div className="mr-md">
                <HemeaVoice width={64} height={64} />
              </div>
              <div>
                <div className="mb-xxs text-h5 font-bold text-neutral-800">Besoin d'aide ?</div>
                <div className="span text-b1 font-normal text-neutral-700">
                  Contacter{' '}
                  <span className="font-medium">
                    {partner.managerFirstName} {partner.managerLastName}
                  </span>
                  , {roleDescriptionPhrase},{' '}
                  {partner.managerPhone ? (
                    <>
                      au <span className="font-medium">{partner.managerPhone}</span> ou par email{' '}
                      <span className="font-medium">{partner.managerEmail}</span>
                    </>
                  ) : (
                    <>
                      par email <span className="font-medium">{partner.managerEmail}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
        <ActionCards urlDashboardReporting={partner.urlDashboardReporting} />
      </div>
    </LayoutGrid>
  );
};
