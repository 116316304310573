import React from 'react';

import { useNavigate } from 'react-router-dom';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';

import { Chantier } from 'features/EspaceClient3/types';

import { useRejectPv } from '../../api/useRejectPv';

type OwnProps = {
  pv: ChantierPvWithDocuments;
  chantier: Chantier;
  rejectionReason: string;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const RejectPvModal: React.FC<Props> = ({ handleClose, pv, chantier, rejectionReason }) => {
  const { rejectPv, isLoading } = useRejectPv();
  const navigate = useNavigate();

  const showFrozenSentence = !!chantier.montantGele;

  return (
    <FeedbackMessages
      isOpen
      title="Refuser le document"
      variant="warning"
      validateAction={{
        label: 'Refuser le document',
        onClick: async () => {
          await rejectPv({
            pvUuid: pv.uuid,
            pvType: pv.pvType,
            chantierUuid: pv.chantierUuid,
            rejectionReason,
          });
          handleClose();
          navigate(-1);
        },
        disabled: isLoading,
      }}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
      message={
        <div className="font-normal text-b2 text-gray-600">
          Nous allons transmettre à l’entreprise le motif de votre refus et celle-ci devra vous
          soumettre un nouveau document pour validation.
          {showFrozenSentence && (
            <> Les fonds resteront gelés tant que vous n’avez pas validé le document.</>
          )}
        </div>
      }
    />
  );
};

export const useOpenRejectPvModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(RejectPvModal, {
      ...props,
      size: 'md',
    });
};
