import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Primes } from '@travauxlib/shared/src/features/LDDToolbar/components/Primes';
import { ResteAPayer } from '@travauxlib/shared/src/features/LDDToolbar/components/ResteAPayer';
import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { TypeSuivi } from '@travauxlib/shared/src/types';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';

import { useCurrentDevis } from 'features/DevisPro/components/CurrentDevisContext';
import { Devis } from 'types';

type Props = {
  devis: Devis;
  shouldShowHemeaModalites: boolean;
};

export const DevisClientOptions: React.FC<Props> = ({ devis, shouldShowHemeaModalites }) => {
  const { typeSuivi } = useCurrentDevis();
  return (
    <div className="mb-md">
      <SimpleTotal
        className="font-bold"
        label="Total travaux TTC"
        total={devis.prixTotalTTCAvantPrimes}
      />
      {devis.primes.length !== 0 && (
        <>
          <div className="mt-xxs">
            <Primes primes={devis.primes} />
          </div>
          {shouldShowHemeaModalites && (
            <ResteAPayer className="mt-xxs font-bold" amount={devis.prixTotalTTC} />
          )}
        </>
      )}
      {shouldShowHemeaModalites && (
        <div className="mt-md">
          {devis.hasServiceHemeaIncluded && devis.prixTotalTTC >= 0 && (
            <div className="mb-xxs">
              <SimpleTotal
                className="font-bold"
                label="Service hemea TTC"
                total={devis.prixServiceHemeaTTC}
              />
              <div className="text-b2 text-neutral-700">
                Incluant la protection hemea à hauteur de{' '}
                <EURCurrency amount={devis.montantProtectionHemea} />
              </div>
            </div>
          )}
          <div className="font-bold">Suivi de votre chantier</div>
          <div
            className="flex justify-between"
            role="section"
            aria-label={typeSuiviWordings[typeSuivi].commercialLabel}
          >
            {typeSuiviWordings[typeSuivi].commercialLabel}
            {typeSuivi === TypeSuivi.CustomerCare || typeSuivi === TypeSuivi.ServiceHemea ? (
              <span>Inclus</span>
            ) : (
              <EURCurrency amount={devis.prixTravauxPlannerTTC} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
