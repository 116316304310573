import React from 'react';

import WorkmenPicto from '@travauxlib/shared/src/components/DesignSystem/assets/WorkmenPicto.svg?react';

import Bnp from './assets/bnp-paribas.jpg';
import Calendar from './assets/calendar.png';

type Feature = {
  Icon: string;
  Icon2x?: string;
  isSvgIcon?: boolean;
  title: string;
  description: React.ReactNode;
};

const features: (value: boolean) => Feature[] = () => [
  {
    Icon: Bnp,
    title: 'Paiements sécurisés',
    description: 'Votre acompte est bloqué sur un compte séquestre BNP Paribas.',
  },
  {
    Icon: WorkmenPicto,
    isSvgIcon: true,
    title: 'Garantie décennale',
    description: <>L&apos;entreprise est assurée sur l&apos;ensemble des corps de métier.</>,
  },
  {
    Icon: Calendar,
    title: 'Pénalités de retard',
    description: 'Pénalités de retard incluses sur tous les contrats sécurisés hemea.',
  },
];

type Props = {
  isArchitecte: boolean;
};

export const Atout: React.FC<Props> = ({ isArchitecte }) => {
  const featuresList = features(isArchitecte);

  return (
    <div data-testid="hemea-features" className="mt-md mb-xl">
      <hr />
      <div className="flex flex-col sm-desktop:flex-row">
        {featuresList.map(({ Icon, Icon2x, isSvgIcon, title, description }) => (
          <div key={title} className="!px-md sm-desktop:w-1/3 mb-sm last:mb-0 sm-desktop:mb-0">
            <div className="flex items-center sm-desktop:items-start">
              {isSvgIcon ? (
                <div className="h-xl min-w-xl mr-sm sm-desktop:mt-xxs">
                  <Icon />
                </div>
              ) : (
                <img
                  className="mr-sm sm-desktop:mt-xxs"
                  height="32px"
                  width="32px"
                  src={Icon}
                  srcSet={Icon2x && `${Icon2x} 2x`}
                  alt=""
                />
              )}
              <div className="text-sm">
                <div className="font-bold">{title}</div>
                <div>
                  <span>{description}</span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <hr />
    </div>
  );
};
