import React from 'react';

import classNames from 'classnames';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const StructureCard: React.FC<Props> = ({ children, className }) => (
  <div className={classNames('bg-neutral-0 p-md tablet:rounded-xxs shadow-xxs', className)}>
    {children}
  </div>
);

export const CardTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="text-b1 font-medium">{children}</div>
);
