import React from 'react';

import { EmptyDevisFactures } from '../features/DevisFactures/components/EmptyDevisFactures';
import { Documents } from '../features/Documents';

export const EmptyDocuments: React.FC = () => (
  <div className="flex flex-col gap-md">
    <div className="mx-md sm-desktop:mx-0">
      <div className="text-h5 font-bold my-sm">Mes devis</div>
      <EmptyDevisFactures />
    </div>
    <div>
      <Documents />
    </div>
  </div>
);
