import React, { useEffect } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { Person } from '@travauxlib/shared/src/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { RootPdf } from 'components/RootPdf';

import { useEstimation } from './api/useEstimation';

import { Estimation } from '../../types';
import { categories, CategoryWrapper } from '../Questions/utils/categories';

type Props = {
  isClient?: boolean;
};

export const EstimateurPrint: React.FC<Props> = ({ isClient }) => {
  const { uuid } = useParams();
  const { estimationContext, isDevis } = useEstimation({ uuid: uuid!, isClient });
  useEffect(() => {
    if (estimationContext) {
      window.status = 'ready';
    }
  }, [estimationContext]);

  const {
    estimation,
    logoUrl,
    showLogoInPdf,
    internalAccountManager,
  }: {
    estimation?: Estimation;
    logoUrl?: string;
    showLogoInPdf?: boolean;
    internalAccountManager?: Person;
  } = estimationContext || {};

  if (!estimation || !estimation.result) {
    return null;
  }

  const {
    context: { surfaceTotale, locations },
  } = estimation;

  return (
    <RootPdf>
      <div className="container-sm mt-xl">
        <div className="text-center">
          <div className="block text-left">
            <div className="flex flex-wrap -mx-md mb-lg align-middle !justify-center">
              <div className="align-middle w-full px-md max-w-1/2">
                <h2>
                  Projet :{' '}
                  {estimation.title
                    ? estimation.title
                    : `${estimation.firstName} ${estimation.lastName}`}
                </h2>
              </div>

              <div className="w-full px-md max-w-1/2 align-middle text-right">
                {logoUrl && showLogoInPdf && (
                  <img data-testid="logo" alt="" width="80" height="auto" src={logoUrl} />
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-md mb-lg align-middle !justify-center">
              <div className="align-middle w-full px-md max-w-1/2">
                {estimation.googleMapsAddress && estimation.googleMapsAddress.formatted_address}
                <br />
                {estimation.email && <span className="mr-sm">{estimation.email}</span>}
                {estimation.phoneNumber}
              </div>

              <div className="w-full px-md max-w-1/2 align-middle text-right">
                {internalAccountManager && (
                  <div>
                    {internalAccountManager.firstName} {internalAccountManager.lastName}
                    <br />
                    {internalAccountManager.email}
                    <br />
                    {internalAccountManager.mobilePhoneNumber}
                  </div>
                )}
              </div>
            </div>
            <div className="!text-sm mx-0 border flex flex-wrap -mx-md py-sm mb-xxl">
              <div className="w-full px-md max-w-3/12">
                <img
                  className="mb-sm"
                  alt="hemea"
                  src={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=26`}
                  srcSet={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=52 2x`}
                />
                <a className="block text-primary hover:underline" href="https://www.hemea.com">
                  www.hemea.com
                </a>
                <div>01 84 80 50 07</div>
                <a className="block text-primary hover:underline" href="mailto:contact@hemea.com">
                  contact@hemea.com
                </a>
              </div>
              <div className="w-full px-md max-w-9/12 mt-xs">
                <p>
                  De la conception aux finitions, l’équipe hemea assure un suivi de projet à toutes
                  les étapes, accompagnée d’entrepreneurs rigoureusement sélectionnés.
                </p>

                <p className="mb-0">
                  Choisissez la sérénité et des prestations de qualité en confiant votre projet à
                  hemea.
                </p>
              </div>
            </div>
            {isDevis ? (
              <>
                <h3 className="mb-xs font-bold">Devis du {formatFrenchDate(dayjs())}</h3>
                <small className="text-gray-600 mb-lg block">Ce devis est valable 1 mois.</small>
              </>
            ) : (
              <>
                <h3 className="mb-xs font-bold">Estimation du {formatFrenchDate(dayjs())}</h3>
                <small className="text-gray-600 mb-lg block">
                  Cette estimation est une simulation déclarative et ne tient pas compte des
                  spécificités du bien.
                </small>
              </>
            )}
            <div className="mb-xl">
              <h4>Localisations</h4>
              <div className="flex flex-wrap -mx-md">
                <div className="w-full px-md max-w-4/12">
                  <table className="table table-bordered table-striped text-right">
                    <tbody>
                      {locations.map(location => (
                        <tr key={location.uuid}>
                          <td>{location.label}</td>
                          <td>{location.surface.toLocaleString()} m²</td>
                        </tr>
                      ))}
                      <tr>
                        <th>Surface totale</th>
                        <th>{surfaceTotale.toLocaleString()} m²</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {estimation.result.lots.map(({ label, totalHT, lignes }) => (
              <div className="pb-sm" key={label}>
                <div className="flex flex-wrap -mx-md">
                  <h4 className="w-full px-md max-w-10/12">{label}</h4>
                  <h4 className="text-right w-full px-md max-w-2/12">
                    <EURCurrency amount={totalHT} />
                  </h4>
                </div>
                {lignes.map(
                  ({
                    designation,
                    description,
                    quantity,
                    prixHT,
                    unitLabel,
                    answerType,
                    isDeleted,
                  }) => {
                    const category = categories.find(({ value }) => value === answerType);
                    return (
                      <CategoryWrapper
                        key={designation}
                        className={classNames(
                          'py-sm mb-md text-b1 flex flex-wrap -mx-md break-inside-avoid',
                          { '!bg-neutral-100 line-through !text-neutral-600': isDeleted },
                        )}
                        category={category}
                      >
                        <div className="w-full px-md max-w-8/12">{designation}</div>
                        <div className="w-full px-md max-w-2/12 text-right">
                          {quantity.toLocaleString()} {unitLabel}
                        </div>
                        <div className="text-right w-full px-md max-w-2/12">
                          <EURCurrency amount={prixHT} />
                        </div>
                        <small className="mt-xs w-full px-md text-gray-600">
                          <MultilineParagraph text={description} paragraphClassName="mb-0" />
                        </small>
                      </CategoryWrapper>
                    );
                  },
                )}
              </div>
            ))}
            <div className="text-right">
              <div className="font-bold mb-xs">
                Total HT :&nbsp;
                <EURCurrency amount={estimation.result.totalHT} />{' '}
              </div>
              <div className="mb-xs">
                TVA :&nbsp;
                <EURCurrency amount={estimation.result.totalTVA} />{' '}
              </div>
              <h3 className="mb-xs font-bold">
                Total TTC :&nbsp;
                <EURCurrency amount={estimation.result.totalTTC} />{' '}
              </h3>
              {isDevis && (
                <small className="text-gray-600">
                  Ce devis ne peut être signé qu'électroniquement.
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    </RootPdf>
  );
};
