import React from 'react';

import _sum from 'lodash/sum';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { SuiviLot } from '@travauxlib/shared/src/types';

type Props = {
  lots: SuiviLot[];
};

export const SuiviChantierLotsSommaire: React.FC<Props> = ({ lots }) => {
  if (lots.length === 0) {
    return null;
  }

  return (
    <div className="overflow-auto w-full">
      <h4>Sommaire des lots</h4>
      {lots.map((lot, lotIndex) => {
        const lotTrueAmount = _sum(
          lot.lignes.flatMap(ligne => ligne.prixUnitaireHT * ligne.quantite),
        );
        return (
          <div key={lot.uuid} className="flex justify-between pb-sm">
            <div className="flex">
              <div className="flex text-gray-600 !text-sm pt-xxs pr-xs">{`${lotIndex + 1}.0`}</div>
              <div className="flex justify-end"> {lot.label}</div>
            </div>
            <div className="pl-md whitespace-nowrap">
              <EURCurrency amount={lotTrueAmount} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
