import React from 'react';

import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { required } from '@travauxlib/shared/src/utils/form';

export const MotifRefusField: React.FC = () => (
  <div>
    <label className="mb-md text-neutral-700 text-b1" htmlFor="rejectionReason">
      Quel est le motif de votre refus ?
    </label>
    <div className="mb-md">
      <TextareaField
        id="rejectionReason"
        placeholder="Motif du refus"
        rows={5}
        validate={required}
        name="rejectionReason"
      />
    </div>
  </div>
);
