import React from 'react';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Popover } from '@travauxlib/shared/src/components/DesignSystem/components/Popover';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

type Props = {
  appelDePaiementValidatedAmount: number;
};

export const LiberationFondsEnCoursCard: React.FC<Props> = ({ appelDePaiementValidatedAmount }) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <Card
      className="flex-grow mx-md sm-desktop:mx-0 !rounded-md"
      bodyClassNames="!pl-md !py-sm !pr-sm"
      aria-label="Libération fonds en cours"
      role="section"
    >
      <div className="flex items-center gap-xxs">
        <div className="text-b1 font-bold text-neutral-800 mb-xxs">
          Libération de fonds en cours
        </div>
        <Popover
          title="Vérification en cours"
          content="Les fonds vont être transférés à l'entreprise réalisant vos travaux. hemea s'assure que tout est conforme avant de procéder à la libération."
          label="Fermer"
          position={isTabletOrMobile ? 'bottom-end' : 'bottom'}
          trigger={(onClose, referenceProps) => (
            <IconButton
              className="!text-neutral-600"
              onClick={onClose}
              size="sm"
              {...referenceProps}
            >
              <HelpCircleOutline />
            </IconButton>
          )}
        />
      </div>
      <EURCurrency className="text-b2 font-medium" amount={appelDePaiementValidatedAmount} />
    </Card>
  );
};
