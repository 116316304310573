import React from 'react';

import { Project } from 'features/EspaceClient3/types';

type Props = {
  additionalPresentationInfo?: Project['additionalPresentationInfo'];
};

export const Title: React.FC<Props> = ({ additionalPresentationInfo }) => (
  <div className={additionalPresentationInfo ? 'my-sm' : 'mt-sm'}>
    {additionalPresentationInfo?.typeProjet && additionalPresentationInfo.typeProjet !== 'N/A' && (
      <div className="text-neutral-600 text-b2 font-bold">
        {additionalPresentationInfo.typeProjet}
      </div>
    )}
    {additionalPresentationInfo?.shortTitleFormatted && (
      <div className="text-neutral-800 font-bold text-h5">
        {additionalPresentationInfo?.shortTitleFormatted}
      </div>
    )}
  </div>
);
