import React from 'react';

import KeyFigures from '@travauxlib/shared/src/components/DesignSystem/assets/KeyFigures.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import {
  ChantierTransaction,
  TransactionType,
} from '@travauxlib/shared/src/features/Chantiers/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

const TransactionTypeLabels: { [K in TransactionType]: string } = {
  liberation_de_fonds: 'Paiement',
  encaissement_tp: 'Travaux Planner',
  encaissement_service_hemea: 'Service hemea',
  encaissement_archi_interne: 'Paiement',
};

const getTransactionLabel = (transaction: ChantierTransaction): string => {
  if (transaction.transactionType) {
    return TransactionTypeLabels[transaction.transactionType];
  }
  if (transaction.amount > 0) {
    return 'Provision';
  }
  return `Paiement vers ${transaction.creditedAccountName}`;
};

export const TransactionsHistoryTable: React.FC<{ transactions: ChantierTransaction[] }> = ({
  transactions,
}) =>
  transactions.length <= 0 ? (
    <EmptyState
      title="Votre historique est vide"
      description="Vous n'avez pas encore effectué de provisions sur votre compte séquestre"
      illustration={<KeyFigures />}
      iconIllustrationSize="md"
    />
  ) : (
    <Table
      columnConfigs={[
        {
          name: 'Date',
          columnKey: 'date',
          renderValue: (date: string) => <>{formatFrenchDate(date)}</>,
        },
        {
          name: 'Événement',
          columnKey: 'transactionType',
          renderValue: (transactionType, transactions) => (
            <>
              {transactions.isIgnored ? (
                <Tag size="sm" variant="info" label="Opération technique" className="mr-xs" />
              ) : (
                ''
              )}
              {getTransactionLabel(transactions)}
            </>
          ),
          className: 'w-full',
        },
        {
          name: 'Montant',
          columnKey: 'amount',
          renderValue: (amount: number) => (
            <div className="!text-right">
              <Tag
                size="md"
                variant={amount > 0 ? 'info' : 'success'}
                label={<EURCurrency prefix={amount > 0 ? '+' : ''} amount={amount} />}
              />
            </div>
          ),
        },
      ]}
      className="text-b2"
      items={transactions}
      defaultSort={{ columnKey: 'date', order: 'desc' }}
    />
  );
