import React from 'react';

import classNames from 'classnames';

import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import { Lot, ProContext } from '@travauxlib/shared/src/types';

import { getSenderNamesFromDevis } from 'features/DevisPro/utils/getSenderNamesFromDevis';
import { Devis } from 'types';

import { PrintedCommentGroup } from './PrintedCommentGroup';

type Props = {
  devis: Devis;
  proContext: ProContext;
};

const findCommentedItemInLigne = (
  lots: Lot[],
  threadUuid: string,
): { itemNumber: string; designation: string; description?: string } => {
  let itemNumber = '';
  let designation = '';
  let description;
  let lotIndex = 0;

  mainLoop: for (const lot of lots) {
    lotIndex += 1;
    for (const item of lot.items) {
      if (item.type === 'ligne' && item.threadUuid === threadUuid) {
        const itemIndex =
          lot.items.filter(item => item.type === 'ligne').findIndex(l => l.uuid === item.uuid) + 1;
        itemNumber = `${lotIndex}.${itemIndex}`;
        designation = item.designation;
        description = item.description;
        break mainLoop;
      }
    }
  }

  return { itemNumber, designation, description };
};

export const PrintedComments: React.FC<Props> = ({ devis, proContext }) => {
  const { lots } = devis;
  const { comments, customization } = proContext;
  const senderNames = getSenderNamesFromDevis(proContext);
  const allCommentGroups = Object.entries(comments!).map(([threadUuid, comments]) => {
    const { itemNumber, designation, description } = findCommentedItemInLigne(lots, threadUuid);

    return {
      itemNumber: itemNumber,
      commentGroup: (
        <PrintedCommentGroup
          comments={comments}
          itemNumber={itemNumber}
          designation={designation}
          senderNames={senderNames}
          description={description}
        />
      ),
    };
  });

  const orderedComments = allCommentGroups.sort(({ itemNumber: a }, { itemNumber: b }) =>
    Number(a) > Number(b) ? 1 : -1,
  );

  return (
    <>
      <ColoredElement
        className={classNames('mb-md text-h4 font-medium', customization?.titleFontStyle)}
        color={customization?.textColor}
      >
        Commentaires
      </ColoredElement>
      {orderedComments.map(({ itemNumber, commentGroup }) => (
        <React.Fragment key={itemNumber}>{commentGroup}</React.Fragment>
      ))}
    </>
  );
};
