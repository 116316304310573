import React from 'react';

import dayjs from 'dayjs';

import Check from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { AnimatedCircleProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress/AnimatedCircleProgress';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { Chantier } from 'features/EspaceClient3/types';

export const PaiementCard: React.FC<{ chantier: Chantier }> = ({
  chantier: { montantTotalTTC, totalPaiements, transactions },
}) => {
  const pourcentageAvancement = (totalPaiements / montantTotalTTC) * 100;
  const lastPaiementDate = transactions
    .filter(transaction => transaction.amount < 0)
    .sort((transactionA, transactionB) =>
      dayjs(transactionA.date).diff(dayjs(transactionB.date)),
    )[0]?.date;
  const lastPaiement = lastPaiementDate ? dayjs(lastPaiementDate).format('DD/MM/YYYY') : 'Aucun';

  return (
    <Card aria-label="paiement" role="section" className="!rounded-md">
      <div className="flex justify-between gap-xs">
        <div>
          <AnimatedCircleProgress
            value={Math.floor(pourcentageAvancement)}
            size="sm"
            variant="success"
            icon={
              pourcentageAvancement === 100 ? (
                <span className="animate-pulse duration-500 ease-in finite">
                  <Check />
                </span>
              ) : undefined
            }
          />
        </div>
        <div className="flex flex-col grow">
          <div className="flex justify-between mb-xs text-neutral-800 font-bold">
            <div className="text-b1">Paiements</div>
          </div>
          {pourcentageAvancement === 100 ? (
            <div className="flex justify-between text-b2">
              <div className="text-neutral-600">Total payé</div>
              <EURCurrency className="font-medium text-neutral-800" amount={totalPaiements} />
            </div>
          ) : (
            <>
              <div className="flex justify-between text-b2">
                <div className="text-neutral-600">Déjà payé</div>
                <EURCurrency className="font-medium text-neutral-800" amount={totalPaiements} />
              </div>
              <div className="flex justify-between text-b2">
                <div className="text-neutral-600">Restant à payer</div>
                <EURCurrency
                  className="font-medium text-neutral-800"
                  amount={montantTotalTTC - totalPaiements}
                />
              </div>
              <div className="flex justify-between text-b2">
                <div className="text-neutral-600">Dernier paiement</div>
                <div className="text-neutral-800">{lastPaiement}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
