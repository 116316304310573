import React from 'react';

export const LegalFooter: React.FC = () => (
  <div className="text-sm text-neutral-600">
    Les résultats des simulations qui peuvent être réalisées sur le site sont à titre indicatif et
    ne constituent pas des offres.
    <br /> Le devis final de votre demande de votre rénovation ainsi que son montant restent soumis
    à l’étude de votre dossier.
  </div>
);
