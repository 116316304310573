import React from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import M from '@travauxlib/shared/src/components/DesignSystem/assets/HemeaM.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { ProContext } from '@travauxlib/shared/src/types';
import { logosUrl } from '@travauxlib/shared/src/utils/urls';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

type Props = {
  proContext: ProContext;
  isForYousign: boolean;
};

export const DevisSignedCard: React.FC<Props> = ({
  proContext: {
    dateSignature,
    deal: { isHemea },
    company: { isArchitecte },
    enableYousign,
  },
  isForYousign,
}) => {
  const femininePrefix = isArchitecte ? 'e' : '';
  const isHemeaSignature = isHemea && dateSignature;
  const displayCertifiedByYousign = isForYousign || (isHemeaSignature && enableYousign);
  const isClubProSignature = !isHemea && dateSignature;

  return (
    <div
      className={classNames(
        'flex items-center justify-between rounded-xs border p-md',
        isClubProSignature ? 'border-primary-400 bg-beige-50' : 'border-info-100  bg-info-50',
      )}
    >
      <div className="mr-xs">
        <div className="text-b1 font-bold">
          {`${devisOrProposition({
            isArchitecte,
            capitalize: true,
          })} signé${femininePrefix} électroniquement`}
        </div>
        {dateSignature && (
          <div className="text-sm text-neutral-700">
            Le&nbsp;{dayjs(dateSignature).format('DD/MM/YYYY [à] HH:mm')}
          </div>
        )}
        {(displayCertifiedByYousign || isClubProSignature) && (
          <div className="mt-md text-sm text-neutral-700">
            {isClubProSignature ? 'Signé avec ' : `Certifié${femininePrefix} par `}
            <strong className="text-neutral">{isClubProSignature ? 'hemea' : 'Yousign'}</strong>
          </div>
        )}
      </div>
      {displayCertifiedByYousign && (
        <ImgLazy
          className="shrink-0 rounded-full"
          url={`${logosUrl}/yousign.png`}
          width={48}
          height={48}
          alt="Logo Yousign"
          eagerLoading
        />
      )}
      {isClubProSignature && (
        <ColoredCircle>
          <M />
        </ColoredCircle>
      )}
      {!displayCertifiedByYousign && !isClubProSignature && <CheckCircle className="h-xl w-xl" />}
    </div>
  );
};
