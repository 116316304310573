import React from 'react';

import classNames from 'classnames';

import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import { DevisCustomization, GarbageCenter } from '@travauxlib/shared/src/types';

const garbageCenterCategoryMap: { [key: string]: string } = {
  carriere: 'Carrière',
  dechetteries_publiques: 'Déchetterie publique',
  centre_de_traitement: 'Centre de traitement',
  collecte_par_prestataire_sur_chantier: 'Collecte par prestataire sur chantier',
};

type GarbageCentersProps = {
  garbageCenters: GarbageCenter[];
  customization: DevisCustomization | undefined;
};

export const GarbageCenters: React.FC<GarbageCentersProps> = ({
  garbageCenters,
  customization,
}) => {
  if (!garbageCenters?.length) {
    return null;
  }

  return (
    <div className="break-inside-avoid">
      <div className="flex flex-col">
        <ColoredElement
          className={classNames('text-h4', customization?.titleFontStyle)}
          color={customization?.textColor}
        >
          Prise en charge et gestion des déchets (estimation*)
        </ColoredElement>
        <span className="italic text-gray-600 text-b2">
          *Estimation : le prix final sera ajusté au vu des quantités réelles constatées en fin de
          chantier
        </span>
      </div>
      <div className="flex flex-col mb-md">
        {garbageCenters &&
          garbageCenters.map((garbageCenter, index) => {
            const { category, customCategory } = garbageCenter;
            const serializedCategory =
              category === 'other' ? customCategory || 'Autre' : garbageCenterCategoryMap[category];
            return (
              <div key={index}>
                {`- Apport de ces déchets dans: ${serializedCategory}, ${garbageCenter.name}, ${garbageCenter.address}`}
              </div>
            );
          })}
      </div>
    </div>
  );
};
