import React from 'react';

import { useFormState } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import {
  required,
  requiredValidEmail,
  validateConditions,
} from '@travauxlib/shared/src/utils/form';

import { StructureCard } from 'components/Card';
import { FormValues, Partenariat, ProjectQuestion } from 'features/OnboardingSolide/types';

import { QuestionsReassurance } from '../../components/QuestionsReassurance';
import { useInscriptionStartedLegacyTracking } from '../../hooks/useInscriptionStartedLegacyTracking';

type Props = {
  isEventAlreadyTriggered: boolean;
  setIsEventAlreadyTriggered: (isEventAlreadyTriggered: boolean) => void;
  partenariat: string | null;
};

export const AccountForm: React.FC<Props> = ({
  isEventAlreadyTriggered,
  setIsEventAlreadyTriggered,
  partenariat,
}) => {
  const { submitting, values } = useFormState<FormValues>();
  const isBelowTablet = useIsBelowBreakpoint('tablet');
  useInscriptionStartedLegacyTracking(isEventAlreadyTriggered, setIsEventAlreadyTriggered);

  return (
    <>
      <div className="text-h4 font-bold mb-xs">Créer votre compte</div>
      <div className="text-b1 font-medium mb-lg">
        Pour sauvegarder votre projet et accéder à votre espace client
      </div>
      <StructureCard className="-mx-md sm-desktop:mx-0 mb-md">
        <div className="text-b2 text-neutral-600 mb-md">
          Vos données personnelles seront uniquement utilisées dans le cadre de notre service.
          {!values?.localisation?.isLocationAddressable && (
            <div>
              Elles peuvent aussi être utilisées auprès de nos partenaires dans le cadre strict de
              votre projet.
            </div>
          )}
        </div>
        <InputField
          className="mb-md"
          name={ProjectQuestion.FirstName}
          id="firstName"
          label="Prénom"
          validate={required}
        />
        <InputField
          className="mb-md"
          name={ProjectQuestion.LastName}
          id="lastName"
          label="Nom"
          validate={required}
        />
        <InputField
          className="mb-md"
          name={ProjectQuestion.Email}
          id="email"
          label="Email"
          validate={requiredValidEmail}
        />
        <InputField
          className="mb-lg"
          name={ProjectQuestion.PhoneNumber}
          id="phoneNumber"
          label="N° téléphone portable"
          validate={required}
        />
        <div className="mb-md">
          <CheckboxField
            name={ProjectQuestion.Optin}
            id="optin"
            // TODO maybe gérer ça dans les initials values du form plutôt
            defaultValue={false}
            label="Je souhaite recevoir par e-mail des conseils et des informations supplémentaires concernant mes travaux."
          />
        </div>
        <div className="mb-xl">
          <CheckboxField
            name="cgu"
            id="cgu"
            validate={validateConditions}
            label={
              <>
                Je déclare accepter les{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.hemea.com/fr/cgu">
                  Conditions Générales d'Utilisation
                </a>{' '}
                d'hemea.
              </>
            }
          />
        </div>
        <div className="flex flex-col-reverse tablet:flex-row items-center justify-end">
          <Link to="/login" inline disabled={submitting}>
            J'ai déjà un compte
          </Link>
          <Button
            className="mb-lg tablet:mb-0 ml-0 tablet:ml-xl"
            type="submit"
            disabled={submitting}
            loading={submitting}
            fullwidth={isBelowTablet}
          >
            Créer mon compte
            {partenariat === Partenariat.figaro && (
              <img src="https://secure.adnxs.com/px?id=1697501&t=2" width="1" height="1" />
            )}
          </Button>
        </div>
      </StructureCard>
      <QuestionsReassurance />
    </>
  );
};
