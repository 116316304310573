import React from 'react';

import classNames from 'classnames';

import InfoCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleOutline.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { TypeSuivi } from '@travauxlib/shared/src/types';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';

import { DetailsCard } from './DetailsCard';

type Props = {
  selectedTypeSuivi: TypeSuivi;
  prixTravauxPlannerTTC: number;
  tauxTravauxPlanner: number;
  isTS: boolean;
  hasBeenSigned: boolean;
};

export const OfferDetails: React.FC<Props> = ({
  selectedTypeSuivi,
  prixTravauxPlannerTTC,
  tauxTravauxPlanner,
  isTS,
  hasBeenSigned,
}) => (
  <div className="mb-xl break-inside-avoid" id="offer-details">
    <div className="text-h4 font-medium">Suivi de votre chantier</div>
    <div className="mb-md text-h5 font-medium text-neutral-700">
      Vous avez choisi l’offre d’accompagnement suivante
    </div>
    <div className="mb-md">
      <DetailsCard
        isSelected={TypeSuivi.ServiceHemea === selectedTypeSuivi}
        title={typeSuiviWordings[TypeSuivi.ServiceHemea].commercialLabel}
        price="Inclus"
        detailRows={[
          'Votre chargé d’accompagnement travaux est disponible, à distance, en cas de problèmes ou question sur vos travaux',
          'Vous devez vous rendre régulièrement sur le chantier pour suivre l’avancement des travaux et répondre aux questions de l’entrepreneur',
        ]}
        linkUrl={`${APP_CONFIG.wwwURL}/fr/offres/accompagnement`}
        typeSuivi={TypeSuivi.ServiceHemea}
        isTS={isTS}
        hasBeenSigned={hasBeenSigned}
      >
        {({ disabled }) => (
          <>
            <div
              className={classNames('text-b2', disabled ? 'text-neutral-300' : 'text-neutral-700')}
            >
              C’est vous qui gérez votre chantier au quotidien. Votre chargé d’accompagnement
              travaux vous assiste à distance.
            </div>
            <div
              className={classNames(
                'mb-xs flex items-center text-b2 italic',
                disabled ? 'text-neutral-300' : 'text-info-800',
              )}
            >
              <InfoCircleOutline className="mr-xxs w-md" />
              Cette offre est adaptée à ceux qui ont le temps et l’expertise nécessaire au suivi de
              leur chantier.
            </div>
          </>
        )}
      </DetailsCard>
    </div>
    <DetailsCard
      isSelected={TypeSuivi.TravauxPlannerWithService === selectedTypeSuivi}
      title={typeSuiviWordings[TypeSuivi.TravauxPlannerWithService].commercialLabel}
      price={<EURCurrency amount={prixTravauxPlannerTTC} />}
      subPrice={`(${tauxTravauxPlanner}% du montant des travaux, min. 3 500 €)`}
      detailRows={[
        'Réunion avec votre Travaux Planner pour préparer le chantier',
        'Aide et conseils dans la sélection des matériaux',
        'Visite hebdomadaire du chantier par votre Travaux Planner',
        'Rapport hebdomadaire disponible sur votre espace client (avancement, planning, photos de votre intérieur ...)',
      ]}
      linkUrl={`${APP_CONFIG.wwwURL}/fr/offres/accompagnement`}
      typeSuivi={TypeSuivi.TravauxPlannerWithService}
      isTS={isTS}
      hasBeenSigned={hasBeenSigned}
    >
      {({ disabled }) => (
        <>
          <div
            className={classNames('text-b2', disabled ? 'text-neutral-300' : 'text-neutral-700')}
          >
            Votre <span className="font-bold">Travaux Planner</span> effectue le suivi de votre
            chantier et se déplace sur site aux étapes clés de l'avancement. Il gère votre chantier
            pour vous, concentrez-vous sur l’essentiel : votre futur emménagement !
          </div>
          <div
            className={classNames(
              'mb-xs flex items-center text-b2 italic',
              disabled ? 'text-neutral-300' : 'text-info-800',
            )}
          >
            <InfoCircleOutline className="mr-xxs w-md" />
            Cette offre s’adresse à ceux qui souhaitent tout déléguer et qui n’ont ni le temps ni
            les connaissances pour suivre le chantier.
          </div>
        </>
      )}
    </DetailsCard>
  </div>
);
