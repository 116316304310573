import React from 'react';

import { Form } from 'react-final-form';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { canSubmitForm } from '@travauxlib/shared/src/utils/form';

import { Chantier } from 'features/EspaceClient3/types';

import { ConformDocumentField } from './ConformDocumentField';
import { DateSignatureField } from './DateSignatureField';
import { HasReservesField } from './HasReservesField';
import { MotifRefusField } from './MotifRefusField';
import { useOpenRejectPvModal } from './RejectPvModal';
import { useOpenValidatePvModal } from './ValidatePvModal';

type FormValues = {
  isConform?: boolean;
  rejectionReason?: string;
  hasReserves?: boolean;
  dateSignature?: string;
};

type Props = {
  pv: ChantierPvWithDocuments;
  chantier: Chantier;
};

export const ValidatePvForm: React.FC<Props> = ({ pv, chantier }) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const openValidatePvModal = useOpenValidatePvModal();
  const openRejectPvModal = useOpenRejectPvModal();

  return (
    <div className="rounded-xxs bg-white p-md">
      <div className="text-h5 font-bold mb-md">
        Valider le procès-verbal de {PvDocumentName[pv.pvType]}
      </div>
      <div className="flex">
        <div className="w-lg h-lg text-info-700 mr-xs flex items-center shrink-0">
          <InfoCircle />
        </div>
        <span className="text-info-700 text-b1 font-medium">
          Merci de vérifier qu’il s’agit bien du document que vous avez signé avec l’entreprise.
        </span>
      </div>
      <div className="my-md h-[1px] bg-neutral-300" />
      <Form<FormValues>
        onSubmit={async ({ isConform, rejectionReason, hasReserves, dateSignature }) => {
          if (isConform) {
            openValidatePvModal({
              pv,
              chantier,
              hasReserves: hasReserves!,
              dateSignature: dateSignature!,
            });
          } else {
            openRejectPvModal({
              pv,
              rejectionReason: rejectionReason!,
              chantier,
            });
          }
        }}
      >
        {({ handleSubmit, ...formState }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-xl">
              <ConformDocumentField />
              {formState.values.isConform === false && <MotifRefusField />}
              {formState.values.isConform && pv.pvType === PvType.ReceptionChantier && (
                <HasReservesField chantier={chantier} />
              )}
              {((pv.pvType === PvType.LeveeReserves && formState.values.isConform) ||
                formState.values.hasReserves !== undefined) && (
                <DateSignatureField pvType={pv.pvType} />
              )}
            </div>
            <div className="flex justify-end">
              <Button
                fullwidth={isTabletOrMobile}
                type="submit"
                disabled={!canSubmitForm(formState)}
              >
                Valider
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
