import React from 'react';

import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { Prestations } from '@travauxlib/shared/src/types';

type Props = {
  prestations: Prestations;
  displayedPrixTotalTTC: number;
};

export const TotalPrices: React.FC<Props> = ({ prestations, displayedPrixTotalTTC }) => (
  <>
    <SimpleTotal
      className="text-h5 font-bold"
      label={prestations.isAvoir ? 'Total net à déduire' : 'Total TTC'}
      total={displayedPrixTotalTTC}
    />
    {!!prestations.prixTotalFreeTTC && (
      <SimpleTotal
        className="font-bold"
        label="+ Suppléments offerts TTC"
        total={prestations.prixTotalFreeTTC}
        crossedOut
      />
    )}
  </>
);
