import React from 'react';

import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { AppelDePaiement } from '@travauxlib/shared/src/features/Chantiers/types';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

import { useAccount } from 'api/useAccount';

import { useValidateAppelDePaiement } from '../api/useValidateAppelDePaiement';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  appelDePaiement?: AppelDePaiement;
  slug: string;
  proCompanyName: string;
};

export const LiberationFondsModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  appelDePaiement,
  slug,
  proCompanyName,
}) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { isLoading, validateAppelDePaiement } = useValidateAppelDePaiement(uuid!);
  const { account, isLoading: isAccountLoading } = useAccount();

  if (!appelDePaiement) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} title="Confirmer le déblocage des fonds">
      <Form
        onSubmit={async () => {
          await validateAppelDePaiement({
            chantierSlug: slug,
            appelDePaiementUuid: appelDePaiement.uuid,
          });
          handleClose();
        }}
      >
        {({ handleSubmit, submitting, invalid }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Confirmer',
              type: 'submit',
              disabled: invalid || submitting || isAccountLoading,
              loading: isLoading || submitting,
              disabledReason:
                account?.hemeaEmployee &&
                'Vous ne pouvez pas libérer les fonds à la place du client.',
            }}
            cancelAction={{ label: 'Annuler', onClick: handleClose, disabled: submitting }}
          >
            <div className="text-b2 text-neutral-600 mb-md">
              Une demande de paiement est en attente, Dans le cardre de l’avancement de votre
              chantier, votre entreprise vous invite à libérer des fonds depuis votre compte
              séqueste. Voulez-vous confirmer l’action de déblocage des fonds ?
            </div>
            <AlertMessage title="Détail de la demande" level="info">
              <div className="flex-col text-b2 text-neutral-800">
                <div className="flex">
                  Bénéficiaire : <div className="font-medium">&nbsp;{proCompanyName}</div>
                </div>
                <div className="flex">
                  Date :
                  <div className="font-medium">
                    &nbsp;{dateTimeFormat(appelDePaiement?.createdAt)}
                  </div>
                </div>
                <div>
                  Montant : <EURCurrency className="font-medium" amount={appelDePaiement.montant} />
                </div>
              </div>
            </AlertMessage>
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
