import React from 'react';

import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';
import { required } from '@travauxlib/shared/src/utils/form';

export const ConformDocumentField: React.FC = () => (
  <div>
    <div className="mb-md text-neutral-700 text-b1">
      Le document envoyé par l’entreprise est-il conforme ?
    </div>
    <div className="mb-md">
      <RadioButtonsListField
        name="isConform"
        validate={required}
        options={[
          {
            label: 'Oui, le document est conforme',
            value: true,
          },
          {
            label: "Non, le document n'est pas conforme",
            value: false,
          },
        ]}
      />
    </div>
  </div>
);
