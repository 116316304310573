import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { requiredValueInDropdownOptions } from '@travauxlib/shared/src/utils/form';

import { CategoryName, Gamme, QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

const existantsOptions = [
  {
    label: 'Parquet',
    value: 'parquet',
  },
  {
    label: 'Carrelage',
    value: 'carrelage',
  },
  {
    label: 'Moquette',
    value: 'moquette',
  },
  {
    label: 'Vinyle / lino',
    value: 'vinyle',
  },
  {
    label: 'Jonc de mer',
    value: 'jonc_de_mer',
  },
];
const getSouhaitesOptions = (
  isExistantParquet: boolean,
): Array<{ label: string; value: string }> => {
  const commonOptions = [
    {
      label: 'Carrelage',
      value: 'carrelage',
    },
    {
      label: 'Moquette',
      value: 'moquette',
    },
    {
      label: 'Lames vinyle / PVC',
      value: 'vinyle',
    },
  ];

  return isExistantParquet
    ? [
        {
          value: 'poncage_vitrification',
          label: 'Ponçage / vitrification',
        },
        {
          value: 'parquet',
          label: 'Nouveau parquet',
        },
        ...commonOptions,
      ]
    : [
        {
          value: 'parquet',
          label: 'Parquet',
        },
        ...commonOptions,
      ];
};

export type PrestationByLocation = {
  uuid: string;
  existant: string;
  souhaite: string;
};

type FormValues = {
  gamme: Gamme;
  answerType: CategoryName;
  prestationsByLocation?: PrestationByLocation[];
};

export const Sols: React.FC<QuestionProps<FormValues>> = ({
  locations,
  initialValues,
  submitAnswer,
}) => (
  <Form<FormValues>
    initialValues={initialValues}
    onSubmit={({ prestationsByLocation = [], ...values }) => {
      const answer = {
        ...values,
        prestationsByLocation: prestationsByLocation.filter(
          ({ uuid, existant, souhaite }) =>
            uuid && existant !== undefined && souhaite !== undefined,
        ),
      };
      submitAnswer(answer);
    }}
  >
    {({ handleSubmit, values: { prestationsByLocation = [] }, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-xl">
          <Title title="Sols" />
          <div className="text-b2 mb-sm">Quels sols faut-il remplacer ou rénover ?</div>
          <div className="mb-[3.5rem] container p-0">
            {locations.map(({ uuid, label }) => {
              const existingPrestationIndex =
                prestationsByLocation &&
                prestationsByLocation.findIndex(prestation => prestation.uuid === uuid);

              const index =
                existingPrestationIndex !== -1
                  ? existingPrestationIndex
                  : prestationsByLocation.length;

              const prestation = prestationsByLocation[existingPrestationIndex];

              const disabled = !prestation || !prestation.uuid;

              const isExistantParquet = prestation && prestation.existant === 'parquet';

              return (
                <div className="flex flex-wrap -mx-md !items-center mb-lg" key={uuid}>
                  <div className="w-full max-w-4/12 sm-desktop:max-w-3/12 px-md mb-0 mb:mb-sm">
                    <div className="">
                      <CheckboxField
                        id={`prestationsByLocation[${index}].${uuid}`}
                        name={`prestationsByLocation[${index}].uuid`}
                        format={value => !!value}
                        parse={((value: boolean) => (value ? uuid : null)) as any}
                        label={label}
                      />
                    </div>
                  </div>
                  {!disabled && (
                    <>
                      <div className="w-full max-w-8/12 sm-desktop:max-w-3/12 px-md">
                        <DropdownField
                          className="mb-xs sm-desktop:mb-0"
                          data-testid="sol-existant"
                          label="Existant"
                          name={`prestationsByLocation[${index}].existant`}
                          id={`prestationsByLocation-${index}-existant`}
                          validate={requiredValueInDropdownOptions(existantsOptions)}
                          disabled={disabled}
                          options={existantsOptions}
                        />
                      </div>
                      <div className="w-full ml-md/12 sm-desktop:ml-0 max-w-8/12 sm-desktop:max-w-3/12 px-md">
                        <DropdownField
                          className="mb-xs sm-desktop:mb-0"
                          data-testid="sol-souhaite"
                          label="Souhaité"
                          name={`prestationsByLocation[${index}].souhaite`}
                          id={`prestationsByLocation-${index}-souhaite`}
                          validate={requiredValueInDropdownOptions(
                            getSouhaitesOptions(isExistantParquet),
                          )}
                          disabled={disabled}
                          options={getSouhaitesOptions(isExistantParquet)}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="text-center">
          <Button className="mb-[3.5rem]" type="submit" disabled={submitting} loading={submitting}>
            Valider
          </Button>
        </div>
      </form>
    )}
  </Form>
);
